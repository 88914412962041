import { object, string, ref } from 'yup';

export const changePasswordSchema = object().shape({
  password: string().required('Old Password is  required!'),
  newPassword: string()
    .notOneOf([ref('password')], 'New Password must be different from old password.')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
    .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
    .matches(/^(?=.*\d)/, 'Password must contain at least one number')
    .matches(/^(?=.*[@$!%*?&#_])/, 'Password must contain at least one special character. Allowed characters are $!%*?&_#')
    .required('Password is required'),
  confirmPassword: string()
    .oneOf([ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required')
});
