import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import InitiativeActivitiesSkeleton from 'components/@skeletons/InitiativeActivities';
import NoMoreDataComponent from 'components/Accessiblity/InsightBox';
import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import InviteOrAddNetwork from 'components/InitiativeInfoSection/InviteOrAddNetwork';
import messages from '../messages';
import { StyledScrolledGrid } from '../DetailsTab';
import { useInitiativeData, useNetworkData } from './NetworkTabHook';
import NetworkList from './NetworkList';
import paths from 'utils/constants/paths';
import { isAdministrator } from 'utils/utils';
import { useGetRecommendedNetworkQuery } from 'store/rtk-query/initiativesApi';

const NetworkTab = () => {
  const { initiativeId } = useParams();
  const [multipleInviteeList, setMultipleInviteeList] = useState([]);

  const intl = useIntl();

  const {
    userId,
    initiativeDetails,
    initiativeMembers,
    isMembersLoading,
    initiativeAdministrators,
    isAdminsLoading,
    initiativeOwner,
    isOwnerLoading,
    orgAuthority,
    isOrgLoading
  } = useInitiativeData(initiativeId);
  const {
    ownerData = [],
    allMembersData = [],
    allAdminData = []
  } = useNetworkData(
    initiativeDetails,
    initiativeMembers,
    initiativeAdministrators,
    isAdminsLoading,
    initiativeOwner,
    isOwnerLoading,
    orgAuthority,
    isMembersLoading,
    isOrgLoading
  );
  const { data: recommendedData } = useGetRecommendedNetworkQuery(initiativeId, {
    skip: !initiativeId
  });

  const visibleForAdministrators = useMemo(() => {
    if (initiativeDetails && userId) {
      return isAdministrator(initiativeDetails, userId);
    }
  }, [initiativeDetails, userId]);

  const combinedAllNetworkData = useMemo(() => {
    const data = {
      Owner: ownerData?.[0] || [],
      Administrators: allAdminData,
      Enterprises: allMembersData.filter((member) => member.label === 'Enterprise'),
      Members: allMembersData.filter((member) => member.label === 'Member')
    };
    return data;
  }, [ownerData, allAdminData, allMembersData]);

  if (isMembersLoading || isOrgLoading) return Array.from(new Array(5)).map((_, index) => <InitiativeActivitiesSkeleton key={index} />);

  if (!Object.values(combinedAllNetworkData)?.some((y) => y?.length !== 0))
    return (
      <NoMoreDataComponent
        redirectLink={`${paths.Initiatives.url}/${initiativeId}`}
        description={intl.formatMessage(messages.NoMembersYetInInitiatives)}
        title={intl.formatMessage(messages.NoNetworkYet)}
        redirectButtonText={intl.formatMessage(messages.BackToInitiaitive)}
      />
    );

  return (
    <Grid container>
      <StyledScrolledGrid item xs={12} md={visibleForAdministrators ? 9 : 12}>
        <NetworkList
          combinedAllNetworkData={combinedAllNetworkData}
          visibleForAdministrators={visibleForAdministrators}
          userId={userId}
          ownerData={ownerData}
        />
      </StyledScrolledGrid>
      {visibleForAdministrators && (
        <StyledScrolledGrid item xs={12} md={visibleForAdministrators && 3}>
          <InviteOrAddNetwork
            multipleInviteeList={multipleInviteeList}
            setMultipleInviteeList={setMultipleInviteeList}
            initiativeId={initiativeId}
            userId={userId}
            administrators={[...allAdminData, ...ownerData]}
            members={allMembersData}
            showRecommendation
            recommendationUsersList={recommendedData?.recommendationUsersList}
            recommendationOrganizationList={recommendedData?.recommendationOrganizationList}
          />
        </StyledScrolledGrid>
      )}
    </Grid>
  );
};

export default NetworkTab;
