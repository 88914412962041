import { createApi } from '@reduxjs/toolkit/query/react';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';
import { RtkBaseQuery } from 'utils/request';

export const licenseTransactionApi = createApi({
  reducerPath: 'licenseTransactionApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.licenseInfo],
  endpoints: (builder) => ({
    userLicenseTransactionInformation: builder.query({
      query: (uuid) => queryEndPoints.getTransactionInfoByUserId(uuid),
      providesTags: [queryTags.licenseInfo],
      transformResponse: (response) => response?.data?.data?.licenseTransaction,
      keepUnusedDataFor: 5 * 60 * 1000 // 5 minutes cache time
    })
  })
});

export const { useUserLicenseTransactionInformationQuery } = licenseTransactionApi;
