/* eslint-disable react/prop-types */
import React from 'react';
import { Box, styled } from '@mui/material';

const SpecialLabel = styled(Box)(({ theme }) => ({
  background: 'transparent',
  '.red-asterisk': {
    color: theme.palette.error.main // or your desired color
  },
  gap: theme.spacing(1),
  color: 'black !important',
  span: {
    '&:first-of-type': {
      fontFamily: `'Poppins', sans-serif`
    }
  }
}));

export const LabelWithAsterisk = ({ label }) => (
  <SpecialLabel>
    <span>{label}</span>
    <span className="red-asterisk"> * </span>
  </SpecialLabel>
);

export const StyledLabelComponent = ({ label }) => (
  <>
    {label}
    <span style={{ color: 'red' }}> *</span>
  </>
);
