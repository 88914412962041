import { Avatar, Box } from '@mui/material';
import React, { useState, memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { IconPencil, IconDotsVertical, IconTrash } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import MenuIconComponent from 'components/@common/MuiMenu';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import messages from './messages';
import { getMinuteDurationFromNow } from 'utils/dateResolvers';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { CommentTextField } from 'components/Forms';
import { getCapitalizeFirst } from 'utils/getCapitalize';

const StyledComment = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.spacing(1, 1.5, 1, 0),
  gap: theme.spacing(1)
}));
const StyledCommentText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.greyLight
}));

export const CommentComponent = memo(
  ({ name, text, createdAt, commentId, commentUserId, deleteAction, updateAction, disableMenuIcon = false, userImage }) => {
    const theme = useTheme();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [editCommentState, setEditCommentState] = useState(text);
    const {
      data: { uuid: userId }
    } = useGetUserInfoQuery();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const isSelfComment = commentUserId === userId;

    const handleClose = () => {
      setAnchorEl(null);
    };
    const options = [
      {
        icon: <IconPencil />,
        id: 0,
        title: intl.formatMessage(messages.EditComment),
        onClick: (event) => {
          setShowInput(!showInput);
          event.stopPropagation();
          handleClose();
        }
      },
      {
        icon: <IconTrash />,
        id: 1,
        title: intl.formatMessage(messages.DeleteComment),
        onClick: (event) => {
          deleteAction();
          event.stopPropagation();
          handleClose();
        }
      }
    ];

    const handleEditClick = (setIsEmojiPickerOpen) => {
      if (editCommentState.trim() !== '') {
        updateAction({
          body: {
            text: editCommentState
          }
        });
        setIsEmojiPickerOpen(false);
        setShowInput(false);
      }
    };

    return (
      <StyledComment>
        <Stack direction="row " gap={1} alignItems={'start'}>
          <Avatar
            src={userImage}
            sx={{ width: 20, height: 20 }}
            // eslint-disable-next-line react/no-children-prop
            children={getCapitalizeFirst(name)}
          />
        </Stack>
        <Stack sx={{ width: '90%' }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'baseline'}>
            <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <Typography
                sx={{
                  color: theme.palette.common.greyLight,
                  maxWidth: '10rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '0.875rem'
                }}
              >
                {name} <span>:</span>
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="flex-start" gap={2}>
              <Typography sx={{ fontSize: '10px', color: theme.palette.common.greyLight }}>
                {getMinuteDurationFromNow(createdAt)}
              </Typography>
              {isSelfComment && !disableMenuIcon && (
                <MenuIconComponent
                  popperWidth="20ch"
                  options={options}
                  anchorEl={anchorEl}
                  open={open}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  id={commentId}
                  renderIcon={<IconDotsVertical size={18} />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '& .MuiButtonBase-root': {
                      padding: 0
                    }
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Stack>
            {showInput ? (
              <Box pt={1.5}>
                <CommentTextField
                  label="Edit comment"
                  value={editCommentState}
                  onChange={(e) => setEditCommentState(e.target.value)}
                  variant="outlined"
                  onKeyDown={handleEditClick}
                  handleSendClick={handleEditClick}
                  message={editCommentState}
                  setMessage={setEditCommentState}
                />
              </Box>
            ) : (
              <StyledCommentText>{text}</StyledCommentText>
            )}
          </Stack>
        </Stack>
      </StyledComment>
    );
  }
);
CommentComponent.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  createdAt: PropTypes.string,
  commentId: PropTypes.number,
  theme: PropTypes.object,
  isEditComment: PropTypes.bool,
  commentUserId: PropTypes.string,
  deleteAction: PropTypes.func,
  updateAction: PropTypes.func,
  disableMenuIcon: PropTypes.bool,
  userImage: PropTypes.string,
  updateCommentIsLoading: PropTypes.bool
};
