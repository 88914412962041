import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { RtkBaseQuery } from '../../utils/request';
import queryEndPoints from 'utils/queryEndPoints';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';

export const tourApi = createApi({
  reducerPath: 'tourApi',
  baseQuery: RtkBaseQuery,
  endpoints: (builder) => ({
    getAllTourStates: builder.query({
      query: () => queryEndPoints.getAllTourStates,
      keepUnusedDataFor: 60 * 60 * 1000, // 60 minute cache time
      transformResponse: (response) => response?.data?.data
    }),
    addTourStates: builder.mutation({
      query: (body) => {
        return {
          url: queryEndPoints.addTourStates(body),
          method: API_REQUEST_METHOD.POST
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: createdTourState } = await queryFulfilled;
          const newTourState = createdTourState?.data?.data;
          dispatch(
            tourApi.util.updateQueryData('getAllTourStates', undefined, () => {
              return newTourState;
            })
          );
        } catch (error) {
          toast.error(error?.message || 'Failed to add tour state!');
        }
      }
    })
  })
});

export const { useGetAllTourStatesQuery, useAddTourStatesMutation } = tourApi;
