import { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Chip, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  IconArrowDownLeft,
  IconBallpen,
  IconClockCancel,
  IconClockHour4,
  IconDotsVertical,
  IconMailForward,
  IconUsersGroup,
  IconChartCircles,
  IconArmchair
} from '@tabler/icons-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import InitiativeDetailsTab from 'components/@skeletons/InitiativeDetails';
import InsightBox from 'components/Accessiblity/InsightBox';
import toast from 'react-hot-toast';
import InitiativeMemberBoxImageWithAvatar from './MemberBoxWithAvatar';
import messages from './messages';
import InitiativeReactionSection from './InitiativeReactionSection';
import { colors } from 'utils/constants/colors';
import {
  useGetInitiativeMembersQuery,
  useGetInitiativesByIdQuery,
  useLazyJoinInitiativesRequestQuery,
  useUpdateInitiativesMutation,
  useLeaveInitiativeMutation,
  useUserMessageToOwnerMutation,
  useAddWatchedInitiativesMutation,
  useRemoveWatchedInitiativesMutation,
  useCheckInitiativeInWatchedListQuery,
  useCheckUserInAuthorityQuery
} from 'store/rtk-query/initiativesApi';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import {
  createMarkup,
  isAdministrator,
  isVisible,
  isJoinInitiativesPermitted,
  isMember,
  isAuthorizedEntity,
  isInvitee,
  createUserName,
  isOwner
} from 'utils/utils';
import { formatDateTime } from 'utils/dateResolvers';
import removeUnderScore from 'utils/removeUnderscore';
import { initiativeStates } from 'utils/constants/initiatives';
import { imagePathResolver } from 'utils/imageResolvers';
import globalConstant, { RESPONSE } from 'utils/constants/globalConstant';
import { Dialog, MenuIconComponent } from 'components/@common';
import { useGetInitiativeInviteeQuery } from 'store/rtk-query/inviteeApi';
import paths from 'utils/constants/paths';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 0,
  boxShadow: 'none',
  borderBottom: `0.5px solid ${theme.palette.primary.lighter}`
}));

export const StyledScrolledGrid = styled(Grid)(() => ({
  height: '75vh',
  overflow: 'scroll',
  scrollbarWidth: 'none'
}));

const initiativeAccessDetails = [
  { title: <FormattedMessage id={'InitiativeType'} defaultMessage={'Initiative Type'} />, accessor: 'type' },
  { title: <FormattedMessage id={'InitiativeSubType'} defaultMessage={'Initiative Sub Type'} />, accessor: 'subType' },
  { title: <FormattedMessage id={'Privacy'} defaultMessage={'Privacy'} />, accessor: 'privacy' },
  { title: <FormattedMessage id={'Visibility'} defaultMessage={'Visibility'} />, accessor: 'visibility' },
  { title: <FormattedMessage id={'industrySector'} defaultMessage={'Industry Sector'} />, accessor: 'industrySector' },
  { title: <FormattedMessage id={'CreatedAt'} defaultMessage={'Created At'} />, accessor: 'createdAt' }
];

const DetailsTab = () => {
  const intl = useIntl();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { initiativeId } = useParams();
  const [archiveInitiativeDialogIsOpen, setArchiveInitiativeDialogIsOpen] = useState(false);

  const [contactAdminsDialogIsOpen, setContactAdminsDialogIsOpen] = useState(false);
  const [contactAdminMessage, setContactAdminMessage] = useState('');

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    data: { uuid: userId, profileImage }
  } = useGetUserInfoQuery();

  const { data: initiativeMembers } = useGetInitiativeMembersQuery(initiativeId, { skip: !initiativeId });
  const { data: initiativeInvitees } = useGetInitiativeInviteeQuery({ initiativeId }, { skip: !initiativeId });
  const { data: checkWatchedlist, refetch: refetchCheckWatchedlist } = useCheckInitiativeInWatchedListQuery(initiativeId, {
    skip: !initiativeId,
    refetchOnMountOrArgChange: true
  });
  const { data: userAuthority } = useCheckUserInAuthorityQuery(initiativeId, {
    skip: !initiativeId,
    refetchOnMountOrArgChange: true
  });
  const isInWatchedList = checkWatchedlist?.data?.data;
  const userInAuthority = userAuthority?.data?.data;

  const {
    data: initiativeDetails,
    isLoading: initiativeInfoLoading,
    error
  } = useGetInitiativesByIdQuery(
    { userId, initiativeId },
    {
      skip: !initiativeId || !userId,
      refetchOnMountOrArgChange: true
    }
  );

  const iconSx = {
    color: palette.primary.mainLight,
    marginRight: '0.5rem',
    marginLeft: '0.5rem'
  };

  const archivedStates = initiativeStates.ARCHIVED === initiativeDetails?.initiativeState;
  const draftStates = initiativeStates.DRAFTED === initiativeDetails?.initiativeState;

  const [joinInitiativesRequest] = useLazyJoinInitiativesRequestQuery();
  const [userMessageToOwner, { isLoading: isUserMessageToOwnerLoading }] = useUserMessageToOwnerMutation();
  const [updateInitiatives, { isLoading: isInitiativeUpdateLoading }] = useUpdateInitiativesMutation();
  const [leaveInitiatives] = useLeaveInitiativeMutation();
  const [addWatchedInitiatives] = useAddWatchedInitiativesMutation();
  const [removeWatchedInitiatives] = useRemoveWatchedInitiativesMutation();

  const isVisibleJoinInitiativesButton = useMemo(() => {
    if (initiativeDetails && userId) {
      return isJoinInitiativesPermitted({ ...initiativeDetails, members: initiativeMembers, isAuthority: userInAuthority }, userId);
    }
  }, [initiativeDetails, initiativeMembers, userId]);

  const handleJoinInitiatives = async () => {
    const response = await joinInitiativesRequest(initiativeId);
    if (response?.data) {
      toast.success(response?.data?.data?.data || 'You have successfully joined the initiative!');
    } else {
      toast.error(response?.error?.data?.data?.error || "This initiative privacy is not public and you can't join in");
    }
    handleClose();
  };

  const handleSubmit = async () => {
    const response = await updateInitiatives({
      body: { initiativeState: archivedStates || draftStates ? initiativeStates.PUBLISHED : initiativeStates.ARCHIVED },
      userId,
      initiativeId
    });
    if (response?.data?.success) {
      toast.success(archivedStates || draftStates ? 'Initiative published successfully !' : 'Initiative archived successfully !');
      setArchiveInitiativeDialogIsOpen(false);
      handleClose();
    } else {
      toast.error('Failed to archive/delete initiative!');
      setArchiveInitiativeDialogIsOpen(false);
      handleClose();
    }
  };

  const handleCloseArchiveInitiativeAlertDialog = () => {
    setArchiveInitiativeDialogIsOpen(false);
  };
  const handleOpenArchiveInitiativeAlertDialog = () => {
    setArchiveInitiativeDialogIsOpen(true);
  };

  const visibleForAdministrators = useMemo(() => {
    if (initiativeDetails && userId) {
      return isAdministrator(initiativeDetails, userId);
    }
  }, [initiativeDetails, userId]);

  const isAdminOrMemberIsNotOwner = useMemo(() => {
    if (initiativeDetails && userId) {
      return (
        (isAdministrator(initiativeDetails, userId) || isMember({ ...initiativeDetails, members: initiativeMembers }, userId)) &&
        !isOwner(initiativeDetails, userId)
      );
    }
  }, [initiativeDetails, initiativeMembers, userId]);

  const handleWatchedInitiatives = async () => {
    let response;
    if (!isInWatchedList) {
      response = await addWatchedInitiatives(initiativeId);
    } else {
      response = await removeWatchedInitiatives({ initiativeId });
    }
    if (response?.data) {
      toast.success(response?.data?.data?.data || 'You have successfully added this initiative in your watch list!');
    } else {
      toast.error(response?.error?.data?.data?.error || 'This initiative is already in your watch list.');
    }
    await refetchCheckWatchedlist(initiativeId);
    handleClose();
  };

  const menuOptions = [
    visibleForAdministrators &&
      !archivedStates && {
        icon: <IconBallpen style={iconSx} />,
        id: 1,
        title: 'Edit Initiative',
        onClick: (event) => {
          event.stopPropagation();
          handleClose();
          navigate(`/initiatives/edit/${event?.target?.slot}`);
        }
      },
    isVisibleJoinInitiativesButton && {
      icon: <IconBallpen style={iconSx} />,
      id: 2,
      title: 'Join Initiative',
      onClick: (event) => {
        event.stopPropagation();
        handleJoinInitiatives();
      }
    },
    visibleForAdministrators &&
      !archivedStates &&
      !draftStates && {
        icon: <IconClockCancel style={iconSx} />,
        id: 3,
        title: 'Archive',
        onClick: () => {
          handleOpenArchiveInitiativeAlertDialog();
        }
      },
    (archivedStates || draftStates) &&
      visibleForAdministrators && {
        icon: <IconClockCancel style={iconSx} />,
        id: 4,
        title: 'Publish',
        onClick: () => {
          handleOpenArchiveInitiativeAlertDialog();
        }
      },
    !isAuthorizedEntity(initiativeDetails, userId) &&
      !isAdministrator(initiativeDetails, userId) &&
      !isInvitee({ ...initiativeDetails, invitees: initiativeInvitees }, userId) &&
      !isMember({ ...initiativeDetails, members: initiativeMembers }, userId) && {
        icon: <IconMailForward style={iconSx} />,
        id: 5,
        title: intl.formatMessage(messages.ContactInitiativeAdmin),
        onClick: () => setContactAdminsDialogIsOpen(true)
      },
    {
      icon: <IconClockHour4 style={iconSx} />,
      id: 6,
      title: !isInWatchedList ? intl.formatMessage(messages.AddToWatchList) : intl.formatMessage(messages.RemoveFromWatchList),
      onClick: (event) => {
        event.stopPropagation();
        handleWatchedInitiatives();
      }
    },
    isAdminOrMemberIsNotOwner && {
      icon: <IconArrowDownLeft style={iconSx} />,
      id: 7,
      title: intl.formatMessage(messages.LeaveInitiative),
      onClick: async () => {
        try {
          const result = await leaveInitiatives(initiativeId);
          if (result?.error) {
            toast.error(result?.error?.data?.data?.error || 'Failed to leave initiative!');
            return;
          }
          toast.success(result?.data?.data?.data || 'Initiative left successfully !');
          navigate('/initiatives', { replace: true });
        } catch (errorInitiativeLeave) {
          toast.error(errorInitiativeLeave?.error?.data?.data?.error || 'Failed to leave initiative!');
        } finally {
          handleClose();
        }
      }
    }
  ].filter(Boolean);

  const handleContactAdminsTextSubmit = async () => {
    await userMessageToOwner({ body: contactAdminMessage, initiativeId });
    setContactAdminsDialogIsOpen(false);
    setContactAdminMessage('');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shouldReactionAndCommentsShow = isVisible(initiativeDetails, initiativeMembers, userId);
  const ownerName = initiativeDetails?.organization ? initiativeDetails?.organization?.name : createUserName(initiativeDetails?.userInfo);

  const orgName = (!initiativeDetails?.organization && initiativeDetails?.userInfo?.organization?.name) || '';

  const orgProfilePic = initiativeDetails?.organization
    ? initiativeDetails?.organization?.profileImage
    : initiativeDetails?.userInfo?.profileImage;

  const licenseAdministrators =
    !!initiativeDetails?.administrators && initiativeDetails?.administrators?.length
      ? initiativeDetails?.administrators
      : [{ firstName: 'Anonymous', lastName: '', currentPosition: 'Anonymous', profileImage: '' }];

  if (initiativeInfoLoading) return Array.from(new Array(1)).map((_, index) => <InitiativeDetailsTab key={index} />);
  if (error?.data?.statusCode === RESPONSE.ERROR.FORBIDDEN)
    return (
      <InsightBox
        title="Not Authorized"
        description="You are not allowed to access this page"
        redirectButtonText="Back to initiatives"
        redirectLink={paths.Initiatives.url}
      />
    );

  return (
    <Grid container>
      <StyledScrolledGrid item xs={12} md={shouldReactionAndCommentsShow ? 9 : 12} px={0.5} mt={2}>
        <Grid container spacing={2} position={'relative'}>
          <Grid item xs={12} md={initiativeDetails?.administrators?.length > 3 ? 12 : 5}>
            <Typography variant="h5" fontSize={'1rem'} mb={1}>
              {intl.formatMessage(messages.Owner)}
            </Typography>
            <Item
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '1rem'
              }}
            >
              <InitiativeMemberBoxImageWithAvatar
                label1={ownerName}
                label2={!initiativeDetails?.organization && initiativeDetails?.userInfo?.currentPosition}
                avatarSrc={imagePathResolver(orgProfilePic)}
                label3={orgName}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={initiativeDetails?.administrators?.length > 3 ? 12 : 7}>
            <Typography variant="h5" fontSize={'1rem'} mb={1}>
              {intl.formatMessage(messages.Administors)}
            </Typography>
            <Item
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '1rem'
              }}
            >
              {licenseAdministrators?.map((adminInfo, index) => {
                const { currentPosition, profileImage: profilePicAlias } = adminInfo;
                return (
                  <InitiativeMemberBoxImageWithAvatar
                    key={index}
                    label1={createUserName(adminInfo)}
                    label2={currentPosition}
                    avatarSrc={imagePathResolver(profilePicAlias)}
                  />
                );
              })}
            </Item>
          </Grid>

          <Box sx={{ position: 'absolute', right: 0, top: 16 }}>
            <MenuIconComponent
              options={menuOptions}
              anchorEl={anchorEl}
              open={open}
              handleClick={handleClick}
              handleClose={handleClose}
              id={initiativeId}
              renderIcon={<IconDotsVertical />}
              width={50}
              height={50}
              sx={{
                position: 'absolute',
                top: isMobile ? '-4px' : 0,
                right: isMobile ? '-15px' : 0,
                bgcolor: 'white',
                background: 'transparent',
                boxShadow: 'none',
                color: 'primary.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            />
          </Box>
          <Grid item xs={12} mr={4}>
            <Typography variant="h5" fontSize={'1rem'} mb={1}>
              {intl.formatMessage(messages.Keywords)}
            </Typography>
            <Stack direction={'row'} flexWrap={'wrap'}>
              {initiativeDetails?.keywords?.map((keyword) => {
                return (
                  <Chip
                    key={keyword?.id}
                    sx={{
                      fontSize: '0.8rem',
                      m: '0.2rem',
                      borderRadius: '6px',
                      maxLines: 1,
                      bgcolor: 'secondary.cardBackground',
                      fontWeight: 500,
                      color: 'primary.main',
                      height: 'auto',
                      p: '3px 5px',
                      width: 'max-content'
                    }}
                    label={keyword?.title || 'Anonymous'}
                    variant="filled"
                  />
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} mr={4}>
            <Item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {initiativeAccessDetails?.map((element, index) => {
                const modifiedValue =
                  element?.accessor === 'createdAt'
                    ? formatDateTime(initiativeDetails?.createdAt)
                    : element?.accessor === 'visibility'
                    ? removeUnderScore(initiativeDetails?.visibility || 'Anonymous')
                    : initiativeDetails?.[element.accessor] || 'Anonymous';
                return (
                  <Stack key={index} spacing={1} alignItems={'flex-start'}>
                    <Typography color={'dark.labelTextColor'} variant="h5" fontSize={'1rem'}>
                      {element?.title || 'Anonymous'}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Typography color={colors.dark[550]} variant="h6">
                        {modifiedValue}
                      </Typography>
                      {modifiedValue === globalConstant.COMMUNITY ? (
                        <Box display={'flex'} pl={0.5}>
                          <IconUsersGroup stroke={1.5} color={palette.primary.main} size={18} />
                        </Box>
                      ) : modifiedValue === globalConstant.CAMPAIGN ? (
                        <Box display={'flex'} pl={0.5}>
                          <IconChartCircles stroke={1.5} color={palette.primary.main} size={18} />
                        </Box>
                      ) : modifiedValue === globalConstant.EVENT ? (
                        <Box display={'flex'} pl={0.5}>
                          <IconArmchair stroke={1.5} color={palette.primary.main} size={18} />
                        </Box>
                      ) : null}
                    </Stack>
                  </Stack>
                );
              })}
            </Item>
            <Box dangerouslySetInnerHTML={createMarkup(initiativeDetails?.teaser || 'Anonymous')} sx={{ wordBreak: 'break-word' }} />
          </Grid>
        </Grid>
      </StyledScrolledGrid>

      {shouldReactionAndCommentsShow && (
        <StyledScrolledGrid item xs={12} md={3}>
          <InitiativeReactionSection
            initiativeId={initiativeId}
            userId={userId}
            disableInteraction={initiativeDetails?.initiativeState === initiativeStates.DRAFTED}
            userImage={imagePathResolver(profileImage)}
          />
        </StyledScrolledGrid>
      )}

      <Dialog
        key={0}
        isOpen={contactAdminsDialogIsOpen}
        cancelSubmitHandle={() => {
          setContactAdminMessage('');
          setContactAdminsDialogIsOpen(false);
        }}
        handleClose={() => {
          setContactAdminMessage('');
          setContactAdminsDialogIsOpen(false);
        }}
        content={
          <Grid container width={(theme) => theme.spacing(90)}>
            <TextField
              focused
              fullWidth
              label={intl.formatMessage(messages.MessageLabelForOwner)}
              minRows={4}
              placeholder={intl.formatMessage(messages.WriteMessageForAdmin)}
              onChange={({ target: { value } }) => setContactAdminMessage(value)}
              value={contactAdminMessage}
              multiline
              sx={{
                '& .MuiOutlinedInput-notchedOutline legend': {
                  fontSize: '0.75rem'
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontSize: '16px'
                }
              }}
            />
          </Grid>
        }
        isOkBtnDisable={!contactAdminMessage}
        okSubmitHandle={handleContactAdminsTextSubmit}
        okBtnText={'Send'}
        cancelBtnText="Cancel"
        title={'Contact Admin'}
        loading={isUserMessageToOwnerLoading}
      />
      {archiveInitiativeDialogIsOpen && (
        <Dialog
          key={1}
          isOpen={true}
          cancelSubmitHandle={handleCloseArchiveInitiativeAlertDialog}
          handleClose={handleCloseArchiveInitiativeAlertDialog}
          content={`Are you sure you want to ${archivedStates || draftStates ? 'publish' : 'archive'} this initiative?`}
          okSubmitHandle={handleSubmit}
          okBtnText={archivedStates || draftStates ? 'Publish' : 'Archive'}
          title={archivedStates || draftStates ? 'Publish initiatives' : 'Archive initiative'}
          loading={isInitiativeUpdateLoading}
        />
      )}
    </Grid>
  );
};

export { DetailsTab };
