import { createApi } from '@reduxjs/toolkit/query/react';
import { RtkBaseQuery } from '../../utils/request';
import queryEndPoints from 'utils/queryEndPoints';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';

export const utilityApi = createApi({
  reducerPath: 'utilityApi',
  baseQuery: RtkBaseQuery,
  endpoints: (builder) => ({
    globalSearch: builder.query({
      query: ({ keyword, searchIn }) => queryEndPoints.globalSearch({ keyword, searchIn }),
      transformResponse: (response) => response?.data?.data
    }),
    getAllRoles: builder.query({
      query: () => queryEndPoints.getAllRoles(),
      transformResponse: (response) =>
        response?.data?.data ? (Array.isArray(response?.data?.data) ? response?.data?.data.reverse() : [response?.data?.data]) : [],
      keepUnusedDataFor: 60 * 60 * 1000 // 60 minute cache time
    }),
    addExtractKeywords: builder.mutation({
      query: (body) => {
        return {
          url: queryEndPoints.addExtractKeywords,
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data.map((x) => ({ title: x }))
    })
  })
});

export const { useLazyGlobalSearchQuery, useGetAllRolesQuery, useAddExtractKeywordsMutation } = utilityApi;
