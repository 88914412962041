import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { RtkBaseQuery } from '../../utils/request';
import { userAPI } from './userApi';
import queryTags from 'utils/constants/queryTags';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';
import queryEndPoints from 'utils/queryEndPoints';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.AllOrganizationUser, queryTags.organizationInfo],
  endpoints: (builder) => ({
    getOrganizationInfo: builder.query({
      query: (uuid) => queryEndPoints.getOrganizationInfo(uuid),
      providesTags: [queryTags.organizationInfo],
      keepUnusedDataFor: 2 * 60 * 1000, // 2 minutes cache time
      transformResponse: (response) => response?.data?.data
    }),
    updateOrganization: builder.mutation({
      query: ({ organizationId, body }) => {
        return {
          url: queryEndPoints.updateOrganizationById(organizationId),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const updatedOrganizationDetails = data?.data?.data;
          const fullFilledOrganizationDetailUpdate = dispatch(
            organizationApi.util.upsertQueryData('getOrganizationInfo', organizationId, updatedOrganizationDetails)
          );
          const fullFilledUserInfoRegardingOrganizationDetailUpdate = dispatch(
            userAPI.util.updateQueryData('getUserInfo', undefined, (userData) => {
              const isOrganizationExist = !!userData?.organization;
              if (isOrganizationExist) {
                userData.organization = updatedOrganizationDetails;
              }
            })
          );
          await Promise.all([fullFilledOrganizationDetailUpdate, fullFilledUserInfoRegardingOrganizationDetailUpdate]);
        } catch (error) {
          toast.error(error?.message || 'Failed to update organization details!');
        }
      }
    }),
    getAllOrganizationUsers: builder.query({
      query: ({ organizationId, offset, limit, search }) => queryEndPoints.getAllOrganizationUsers(organizationId, offset, limit, search),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.AllOrganizationUser]
    }),
    AddOrganizationUser: builder.mutation({
      query: ({ organizationId, body }) => {
        return {
          url: queryEndPoints.AddUserInOrganization(organizationId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      invalidatesTags: [queryTags.AllOrganizationUser]
    }),
    updateOrganizationUser: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: queryEndPoints.updateUserInOrganizationById(id),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      invalidatesTags: [queryTags.AllOrganizationUser]
    }),
    deleteOrganizationUser: builder.mutation({
      query: ({ userId, takeOwnership }) => {
        return {
          url: queryEndPoints.deleteUserInOrganizationById(userId, takeOwnership),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      invalidatesTags: [queryTags.AllOrganizationUser]
    }),
    uploadOrganizationProfileImage: builder.mutation({
      query: ({ organizationId, imageFile }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', imageFile);
        return {
          url: queryEndPoints.uploadOrganizationProfileImage(organizationId),
          method: API_REQUEST_METHOD.POST,
          body: bodyFormData
        };
      },
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const updateOrgDetails = data?.data?.data;
        await dispatch(organizationApi.util.upsertQueryData('getOrganizationInfo', organizationId, updateOrgDetails));
      }
    })
  })
});

export const {
  useGetOrganizationInfoQuery,
  useGetAllOrganizationUsersQuery,
  useAddOrganizationUserMutation,
  useUpdateOrganizationUserMutation,
  useDeleteOrganizationUserMutation,
  useUpdateOrganizationMutation,
  useUploadOrganizationProfileImageMutation
} = organizationApi;
