import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import paths from 'utils/constants/paths';

import globalConstant from 'utils/constants/globalConstant';
import useLocalStorage from 'hooks/useLocalStorage';

const ProtectedRoute = ({ children, redirect = paths.Login.url }) => {
  const [jwtTokenValue] = useLocalStorage(globalConstant.JWT_TOKEN, '');
  let location = useLocation();
  const sessionRedirect = `${redirect}?session_redirect=${location.pathname}${location.search}`;

  if (!jwtTokenValue && location.pathname.includes('/chat')) return <Navigate to={sessionRedirect} state={{ from: location }} replace />;
  if (!jwtTokenValue) return <Navigate to={redirect} state={{ from: location }} replace />;

  return children;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  redirect: PropTypes.string
};
