import React, { cloneElement, useCallback, useMemo, useState } from 'react';
import { Box, Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  IconShare,
  IconBallpen,
  IconClockCancel,
  IconDotsVertical,
  IconTie,
  IconBrandAsana,
  IconUserSquareRounded,
  IconBuildingSkyscraper,
  IconMailForward,
  IconLink,
  IconClockHour4,
  IconArrowDownLeft,
  IconUsersGroup,
  IconChartCircles,
  IconArmchair
} from '@tabler/icons-react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import messages from './messages';
import useElementWidth from 'hooks/useElementWidth';
import {
  IconButton,
  ActionComponent,
  CustomChip,
  MenuIconComponent,
  OneLinerTypographyWithTooltip,
  Dialog,
  MainCard
} from 'components/@common';
import {
  useAddInitiativesReactionMutation,
  useAddWatchedInitiativesMutation,
  useLazyJoinInitiativesRequestQuery,
  useLeaveInitiativeMutation,
  useRemoveWatchedInitiativesMutation,
  useUpdateInitiativesMutation,
  useUserMessageToOwnerMutation
} from 'store/rtk-query/initiativesApi';
import {
  createMarkup,
  getRoleLabelForInitiativeBox,
  isAdministrator,
  isAuthorizedEntity,
  isInvitee,
  isJoinInitiativesPermitted,
  isMember,
  returnIconBasedOnInitiativesStates,
  isUserEligible,
  createUserName,
  isOwner
} from 'utils/utils';
import { colors } from 'utils/constants/colors';
import { INITIATIVE_TYPE, ROLE_LABEL, initiativeStates } from 'utils/constants/initiatives';
import globalConstant from 'utils/constants/globalConstant';

const StaticLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.9rem',
  color: colors.dark[550],
  [theme.breakpoints.down('msm')]: {
    fontSize: '0.75rem'
  }
}));

const StyledRenderHtmlBox = styled(Box)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  color: colors.dark[550]
}));

export const MatchingPercentage = styled(Typography)(({ theme, fontSize }) => ({
  color: theme.palette.success.main,
  background: theme.palette.success.lighter,
  fontSize: fontSize ? fontSize : '14px !important',
  padding: '4px 10px',
  borderRadius: '3px',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: fontSize ? fontSize : '12px !important',
    padding: '4px 6px'
  }
}));

const InitiativesInfoBox = ({
  initiativeInfo,
  userId,
  showLink = false,
  handleLinkInitiative,
  initiativeType = '',
  refetchInitiatives,
  className = ''
}) => {
  const { elementWidth: mainCardWidth, elementRef: mainCardRef } = useElementWidth();
  const {
    uuid: initiativeId,
    title = 'Anonymous',
    privacy = 'Anonymous',
    subType = 'Anonymous',
    type,
    teaser = 'Anonymous',
    industrySector,
    initiativeState,
    userInfo,
    organization = null,
    userHasLiked,
    userHasDisliked,
    likeCount,
    dislikeCount,
    commentCount,
    isLinked = false,
    isInWatchedList = false,
    matchingScore = 0
  } = initiativeInfo;

  const isOrganizationExist = Boolean(organization?.name);

  const ownerName = isOrganizationExist ? organization?.name || 'Anonymous' : createUserName(userInfo);

  const currentReaction = {
    userHasLiked,
    userHasDisliked
  };

  const navigate = useNavigate();
  const intl = useIntl();
  const { palette } = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [addInitiativesReaction] = useAddInitiativesReactionMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [archiveInitiativeDialogIsOpen, setArchiveInitiativeDialogIsOpen] = useState(false);
  const [contactAdminsDialogIsOpen, setContactAdminsDialogIsOpen] = useState(false);
  const [contactAdminMessage, setContactAdminMessage] = useState('');

  const open = Boolean(anchorEl);
  const [updateInitiatives, { isLoading: isInitiativeUpdateLoading }] = useUpdateInitiativesMutation();
  const [addWatchedInitiatives] = useAddWatchedInitiativesMutation();
  const [removeWatchedInitiatives] = useRemoveWatchedInitiativesMutation();

  const [userMessageToOwner, { isLoading: isUserMessageToOwnerLoading }] = useUserMessageToOwnerMutation();
  const [leaveInitiatives] = useLeaveInitiativeMutation();
  const [joinInitiativesRequest] = useLazyJoinInitiativesRequestQuery();
  const currentCompleteUrl = window.location.href.endsWith('/')
    ? `${window.location.href}${initiativeId}`
    : `${window.location.href}/${initiativeId}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseArchiveInitiativeAlertDialog = () => {
    setArchiveInitiativeDialogIsOpen(false);
  };
  const handleOpenArchiveInitiativeAlertDialog = () => {
    setArchiveInitiativeDialogIsOpen(true);
  };

  const visibleForAdministrators = useMemo(() => {
    if (initiativeInfo && userId) {
      return isAdministrator(initiativeInfo, userId);
    }
  }, [initiativeInfo, userId]);

  const isAdminOrMemberIsNotOwner = useMemo(() => {
    if (initiativeInfo && userId) {
      return (isAdministrator(initiativeInfo, userId) || isMember(initiativeInfo, userId)) && !isOwner(initiativeInfo, userId);
    }
  }, [initiativeInfo, userId]);

  const archivedStates = initiativeStates.ARCHIVED === initiativeState;
  const draftStates = initiativeStates.DRAFTED === initiativeState;

  const isVisibleJoinInitiativesButton = useMemo(() => {
    if (initiativeInfo && userId) {
      return isJoinInitiativesPermitted(initiativeInfo, userId);
    }
  }, [initiativeInfo, userId]);

  const handleCopyURL = useCallback(() => {
    try {
      navigator.clipboard.writeText(currentCompleteUrl);
      toast.success(`${intl.formatMessage(messages.CopiedInitiativeURL)}`);
    } catch (error) {
      toast.error(`${intl.formatMessage(messages.FailedCopiedInitiativeURL)}`);
    }
  }, [currentCompleteUrl, intl]);

  const handleSubmit = async () => {
    const response = await updateInitiatives({
      body: { initiativeState: archivedStates || draftStates ? initiativeStates.PUBLISHED : initiativeStates.ARCHIVED },
      userId,
      initiativeId
    });
    if (response?.data?.success) {
      toast.success(archivedStates || draftStates ? 'Initiative published successfully !' : 'Initiative archived successfully !');
      setArchiveInitiativeDialogIsOpen(false);
      handleClose();
    } else {
      toast.error('Failed to archive/delete initiative!');
      setArchiveInitiativeDialogIsOpen(false);
      handleClose();
    }
  };

  const handleContactAdminsTextSubmit = async () => {
    await userMessageToOwner({ body: contactAdminMessage, initiativeId });
    setContactAdminsDialogIsOpen(false);
    setContactAdminMessage('');
  };

  const handleJoinInitiatives = async () => {
    const response = await joinInitiativesRequest(initiativeId);
    if (response?.data) {
      toast.success(response?.data?.data?.data || 'You have successfully joined the initiative!');
    } else {
      toast.error(response?.error?.data?.data?.error || "This initiative privacy is not public and you can't join in");
    }
    handleClose();
  };

  const handleWatchedInitiatives = async () => {
    let response;
    if (!isInWatchedList) {
      response = await addWatchedInitiatives(initiativeId);
    } else {
      response = await removeWatchedInitiatives({
        initiativeId,
        removeFromList: initiativeType === INITIATIVE_TYPE.WATCHED ? true : false
      });
    }
    if (response?.data) {
      toast.success(response?.data?.data?.data || 'You have successfully added this initiative in your watch list!');
    } else {
      toast.error(response?.error?.data?.data?.error || 'This initiative is already in your watch list.');
    }
    handleClose();
  };

  const shouldReactionShow = isUserEligible(initiativeInfo);

  const iconSx = {
    color: palette.primary.mainLight,
    marginRight: '0.5rem',
    marginLeft: '0.5rem'
  };

  const options = [
    visibleForAdministrators &&
      !archivedStates && {
        icon: <IconBallpen style={iconSx} />,
        id: 1,
        title: 'Edit Initiative',
        onClick: (event) => {
          event.stopPropagation();
          handleClose();
          navigate(`/initiatives/edit/${event?.target?.slot}`);
        }
      },
    isVisibleJoinInitiativesButton && {
      icon: <IconBallpen style={iconSx} />,
      id: 2,
      title: 'Join Initiative',
      onClick: (event) => {
        event.stopPropagation();
        handleJoinInitiatives();
      }
    },
    !archivedStates &&
      !draftStates &&
      visibleForAdministrators && {
        icon: <IconClockCancel style={iconSx} />,
        id: 3,
        title: 'Archive',
        onClick: () => {
          handleOpenArchiveInitiativeAlertDialog();
        }
      },
    (archivedStates || draftStates) &&
      visibleForAdministrators && {
        icon: <IconClockCancel style={iconSx} />,
        id: 4,
        title: 'Publish',
        onClick: () => {
          handleOpenArchiveInitiativeAlertDialog();
        }
      },
    !isAuthorizedEntity(initiativeInfo, userId) &&
      !isAdministrator(initiativeInfo, userId) &&
      !isInvitee(initiativeInfo, userId) &&
      !isMember(initiativeInfo, userId) && {
        icon: <IconMailForward style={iconSx} />,
        id: 5,
        title: intl.formatMessage(messages.ContactInitiativeAdmin),
        onClick: () => setContactAdminsDialogIsOpen(true)
      },
    {
      icon: <IconClockHour4 style={iconSx} />,
      id: 6,
      title: !isInWatchedList ? intl.formatMessage(messages.AddToWatchList) : intl.formatMessage(messages.RemoveFromWatchList),
      onClick: (event) => {
        event.stopPropagation();
        handleWatchedInitiatives();
      }
    },
    isAdminOrMemberIsNotOwner && {
      icon: <IconArrowDownLeft style={iconSx} />,
      id: 7,
      title: intl.formatMessage(messages.LeaveInitiative),
      onClick: async () => {
        try {
          const result = await leaveInitiatives(initiativeId);
          if (result.error) {
            toast.error(result?.error?.data?.data?.error || 'Failed to leave initiative!');
            return;
          }
          toast.success(result?.data?.data?.data || 'Initiative left successfully !');
          refetchInitiatives();
        } catch (errorInitiativeLeave) {
          toast.error(errorInitiativeLeave?.error?.data?.data?.error || 'Failed to leave initiative!');
        } finally {
          handleClose();
        }
      }
    }
  ].filter(Boolean);

  const handleNavigateToInitiative = () => {
    if (showLink) return;
    navigate(`/initiatives/${initiativeId}`);
  };

  const handleActionButton = async (reaction_Type) => {
    await addInitiativesReaction({
      body: {
        type: reaction_Type,
        user: {
          uuid: userId
        }
      },
      initiativeId
    });
  };

  const SubHeaderInnofuseBox = () => {
    const roleLabel = useMemo(() => getRoleLabelForInitiativeBox(initiativeInfo, userId), []);
    return (
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1.25}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {cloneElement(returnIconBasedOnInitiativesStates(initiativeState), { style: { color: palette.primary.light } })}
          {!!roleLabel && (
            <CustomChip
              chipSx={{ mt: 0, color: colors.dark[550], p: '0.1rem' }}
              label={roleLabel}
              rightIcon={
                roleLabel === ROLE_LABEL.ADMINISTRATOR ? (
                  <IconTie color={colors.dark[550]} />
                ) : roleLabel === ROLE_LABEL.MEMBER ? (
                  <IconBrandAsana color={colors.dark[550]} />
                ) : null
              }
              variant={'filled'}
            />
          )}
        </Stack>
        {matchingScore !== null && initiativeType !== INITIATIVE_TYPE.SELF && initiativeType !== INITIATIVE_TYPE.WATCHED && (
          <MatchingPercentage className="reactor-initiatives-score">{`${matchingScore.toFixed(2)}% Match`}</MatchingPercentage>
        )}
      </Stack>
    );
  };

  return (
    <>
      <MainCard
        title={
          <OneLinerTypographyWithTooltip
            text={title || 'Anonymous'}
            tooltipText={title || 'Anonymous'}
            sxPropsBox={{
              maxWidth: mainCardWidth * 0.75
            }}
          />
        }
        subheader={<SubHeaderInnofuseBox />}
        sx={{ borderRadius: '10px', boxShadow: '0px 4px 15px 0px rgba(46, 108, 164, 0.09)' }}
        onClickCardHeader={handleNavigateToInitiative}
        ref={mainCardRef}
        className={className}
      >
        {isLinked ? (
          <Button
            variant="contained"
            sx={{
              position: 'absolute',
              top: isMobile ? 15 : 20,
              right: isMobile ? 10 : 20,
              boxShadow: 'none',
              padding: 0
            }}
          >
            Linked
          </Button>
        ) : showLink ? (
          <IconButton
            sx={{
              position: 'absolute',
              top: isMobile ? 0 : 10,
              right: isMobile ? 0 : 10,
              bgcolor: 'white',
              background: 'transparent',
              boxShadow: 'none',
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            onClick={() => handleLinkInitiative(initiativeId)}
          >
            <IconLink size={30} />
          </IconButton>
        ) : (
          <MenuIconComponent
            options={options}
            anchorEl={anchorEl}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            id={initiativeId}
            renderIcon={<IconDotsVertical />}
            width={50}
            height={50}
            sx={{
              position: 'absolute',
              top: isMobile ? '-4px' : 0,
              right: isMobile ? '-15px' : 0,
              bgcolor: 'white',
              background: 'transparent',
              boxShadow: 'none',
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            iconClassName="initiatives-icon"
          />
        )}
        <Stack direction="row">
          <StaticLabel mr={0.5} variant="h6">
            {intl.formatMessage(messages.Owner)}:
          </StaticLabel>
          <Typography color={colors.dark[550]} sx={{ ml: 0.5 }} fontSize={{ xs: '0.75rem', msm: '0.9rem' }}>
            {ownerName}
          </Typography>
          <Box display={'flex'} pl={0.5}>
            {isOrganizationExist ? (
              <IconBuildingSkyscraper stroke={1.5} color={palette.primary.main} size={18} />
            ) : (
              <IconUserSquareRounded stroke={1.5} color={palette.primary.main} size={18} />
            )}
          </Box>
        </Stack>
        <InitiativeDetailLabel label1={intl.formatMessage(messages.Privacy)} label2={privacy} />
        <InitiativeDetailLabel label1={intl.formatMessage(messages.InitiativeType)} label2={subType} type={type} />
        {industrySector && <InitiativeDetailLabel label1={intl.formatMessage(messages.IndustrySector)} label2={industrySector} />}
        <StyledRenderHtmlBox dangerouslySetInnerHTML={createMarkup(teaser || 'Anonymous')} />
        <Divider />
        {shouldReactionShow && (
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
            <Grid item sx={{ display: 'flex', gap: { xs: 1, md: 3 } }}>
              <ActionComponent
                likeCount={likeCount}
                dislikeCount={dislikeCount}
                commentCount={commentCount}
                currentReaction={currentReaction}
                isLiked={userHasLiked}
                isDisLiked={userHasDisliked}
                handleActionButton={handleActionButton}
                handleCommentActionButton={handleNavigateToInitiative}
                disableComment={showLink}
              />
            </Grid>
            <Grid item>
              <>
                <IconButton onClick={handleCopyURL}>
                  <IconShare color={colors.info[450]} />
                </IconButton>
              </>
            </Grid>
          </Grid>
        )}
        {/* TODO : Uncomments when comments are required to show on UI */}
        {/* <Stack pt={1}>
          <Typography color={colors.warmGray[750]}>{intl.formatMessage(messages.Comments)}</Typography>
          <Stack direction="row" gap={1} alignItems={{ md: 'center', xs: 'flex-start' }}>
            <Stack direction="row" gap={0.2} alignItems="center">
              <IconUserCircle color={palette.primary.main} size={15} />
              <Typography color={colors.warmGray[750]}>Me:</Typography>
            </Stack>
            <Typography color={colors.warmGray[750]}>{latestComment}</Typography>
          </Stack>
        </Stack> */}
      </MainCard>
      {archiveInitiativeDialogIsOpen && (
        <Dialog
          isOpen={archiveInitiativeDialogIsOpen}
          cancelSubmitHandle={handleCloseArchiveInitiativeAlertDialog}
          handleClose={handleCloseArchiveInitiativeAlertDialog}
          content={`Are you sure you want to ${archivedStates || draftStates ? 'publish' : 'archive'} this initiative?`}
          okSubmitHandle={handleSubmit}
          okBtnText={archivedStates || draftStates ? 'Publish' : 'Archive'}
          title={archivedStates || draftStates ? 'Publish initiatives' : 'Archive initiative'}
          loading={isInitiativeUpdateLoading}
        />
      )}
      <Dialog
        isOpen={contactAdminsDialogIsOpen}
        cancelSubmitHandle={() => {
          setContactAdminMessage('');
          return setContactAdminsDialogIsOpen(false);
        }}
        handleClose={() => {
          setContactAdminMessage('');
          return setContactAdminsDialogIsOpen(false);
        }}
        content={
          <Grid container width={(theme) => theme.spacing(90)}>
            <TextField
              focused
              fullWidth
              label={intl.formatMessage(messages.MessageLabelForOwner)}
              minRows={4}
              placeholder={intl.formatMessage(messages.WriteMessageForAdmin)}
              onChange={({ target: { value } }) => setContactAdminMessage(value)}
              value={contactAdminMessage}
              multiline
              sx={{
                '& .MuiOutlinedInput-notchedOutline legend': {
                  fontSize: '0.75rem'
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontSize: '16px'
                }
              }}
            />
          </Grid>
        }
        isOkBtnDisable={!contactAdminMessage}
        okSubmitHandle={handleContactAdminsTextSubmit}
        okBtnText={'Send'}
        cancelBtnText="Cancel"
        title={'Contact Admin'}
        loading={isUserMessageToOwnerLoading}
      />
    </>
  );
};

const InitiativeDetailLabel = ({ label1, label2, type }) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row" alignItems={'center'}>
      <StaticLabel mr={0.5} variant="h6">
        {label1}:
      </StaticLabel>
      <Typography color={colors.dark[550]} sx={{ ml: 0.5 }} fontSize={{ xs: '0.75rem', msm: '0.9rem' }}>
        {label2 || 'Anonymous'}
      </Typography>
      {type === globalConstant.COMMUNITY ? (
        <Box display={'flex'} pl={0.5}>
          <IconUsersGroup stroke={1.5} color={palette.primary.main} size={18} />
        </Box>
      ) : type === globalConstant.CAMPAIGN ? (
        <Box display={'flex'} pl={0.5}>
          <IconChartCircles stroke={1.5} color={palette.primary.main} size={18} />
        </Box>
      ) : type === globalConstant.EVENT ? (
        <Box display={'flex'} pl={0.5}>
          <IconArmchair stroke={1.5} color={palette.primary.main} size={18} />
        </Box>
      ) : null}
    </Stack>
  );
};

InitiativeDetailLabel.propTypes = {
  label1: PropTypes.string,
  label2: PropTypes.string,
  type: PropTypes.string
};

InitiativesInfoBox.propTypes = {
  userId: PropTypes.string,
  initiativeInfo: PropTypes.object,
  showLink: PropTypes.bool,
  handleLinkInitiative: PropTypes.func,
  initiativeType: PropTypes.string,
  refetchInitiatives: PropTypes.func,
  className: PropTypes.string
};
export default InitiativesInfoBox;
