import { ItemText } from 'components/NewsInfoSection/NewsListing';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import InitiativeMemberBoxImageWithAvatar from 'components/InitiativeTabs/MemberBoxWithAvatar';
import { IconLinkOff, IconUsersGroup, IconChartCircles, IconArmchair } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { MatchingPercentage } from 'components/InitiativeInfoSection/InitiativesInfoBox';
import { imagePathResolver } from 'utils/imageResolvers';
import { formatDateTime } from 'utils/dateResolvers';
import { ActionComponent, IconButton } from 'components/@common';
import { colors } from 'utils/constants/colors';
import { createUserName } from 'utils/utils';
import { useAddInitiativesReactionMutation, useUnLinkedAnInitiativeMutation } from 'store/rtk-query/initiativesApi';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import globalConstant from 'utils/constants/globalConstant';

const LinkedInitiative = ({ data, index, initiativeId, isAdmin }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [unLinkedAnInitiative] = useUnLinkedAnInitiativeMutation();
  const [addInitiativesReaction] = useAddInitiativesReactionMutation();
  const {
    data: { uuid: userId = '' }
  } = useGetUserInfoQuery();

  const {
    uuid: linkedInitiativeId,
    title = 'Anonymous',
    teaser = 'Anonymous',
    createdAt,
    likeCount = 0,
    userInfo = { profileImage: '', firstName: 'Anonymous', lastName: '' },
    dislikeCount = 0,
    commentCount = 0,
    userHasLiked = false,
    userHasDisliked = false,
    privacy = 'Anonymous',
    subType = 'Anonymous',
    type = '',
    matchingScore = ''
  } = data;

  const handleActionButton = async (reaction_Type) => {
    await addInitiativesReaction({
      body: {
        type: reaction_Type,
        user: {
          uuid: userId
        }
      },
      initiativeId: linkedInitiativeId,
      currentInitiativeId: initiativeId
    });
  };

  const handleUnLinkInitiative = () => {
    unLinkedAnInitiative({ initiativeId, linkedInitiativeId });
  };

  const handleDetailsPageNavigation = () => {
    navigate(`/initiatives/${linkedInitiativeId}`);
  };

  const currentReaction = {
    userHasLiked,
    userHasDisliked
  };

  return (
    <Grid
      mt={index === 0 ? 2 : 0}
      item
      container
      xs={12}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.primary.mainLight}`,
        padding: '12px'
      }}
      spacing={1}
    >
      <Grid
        item
        onClick={handleDetailsPageNavigation}
        sx={{ cursor: 'pointer' }}
        md={5}
        xs={12}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
      >
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <ItemText
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '22px',
              textAlign: 'left'
            }}
          >
            {title || 'Anonymous'}
          </ItemText>
          <MatchingPercentage>{`${Number(matchingScore).toFixed(2)}% Match`}</MatchingPercentage>
        </Stack>
        <ItemText
          sx={{
            color: colors.dark[550],
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.2px',
            textAlign: 'left',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word'
          }}
          dangerouslySetInnerHTML={{ __html: teaser || 'Anonymous' }}
        />
      </Grid>
      <Grid
        item
        onClick={handleDetailsPageNavigation}
        sx={{ cursor: 'pointer' }}
        md={5}
        xs={8}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
      >
        <Stack direction="row" justifyContent={'center'} gap={2} alignItems={'flex-start'}>
          <InitiativeMemberBoxImageWithAvatar
            avatarSrc={imagePathResolver(userInfo?.profileImage)}
            label1={createUserName(userInfo || { firstName: 'Anonymous', lastName: '' })}
            label2={formatDateTime(createdAt || 'Anonymous')}
          />
        </Stack>
        <Stack>
          <Typography> Privacy : {privacy}</Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography> Initiative type : {subType}</Typography>
            {type === globalConstant.COMMUNITY ? (
              <Box display={'flex'} pl={0.5}>
                <IconUsersGroup stroke={1.5} color={palette.primary.main} size={18} />
              </Box>
            ) : type === globalConstant.CAMPAIGN ? (
              <Box display={'flex'} pl={0.5}>
                <IconChartCircles stroke={1.5} color={palette.primary.main} size={18} />
              </Box>
            ) : type === globalConstant.EVENT ? (
              <Box display={'flex'} pl={0.5}>
                <IconArmchair stroke={1.5} color={palette.primary.main} size={18} />
              </Box>
            ) : null}
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        md={2}
        xs={4}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'end'}
        justifyContent={{ md: 'space-around', xs: 'flex-end' }}
      >
        <ActionComponent
          likeCount={likeCount}
          dislikeCount={dislikeCount}
          commentCount={commentCount}
          currentReaction={currentReaction}
          isLiked={userHasLiked}
          isDisLiked={userHasDisliked}
          handleActionButton={handleActionButton}
          handleCommentActionButton={handleDetailsPageNavigation}
        />
        {isAdmin && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={1}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
            onClick={handleUnLinkInitiative}
          >
            <Typography variant="h6" fontWeight={500} color={colors.dark[550]} sx={{ textDecoration: 'underline' }}>
              Unlink
            </Typography>
            <IconButton>
              <IconLinkOff />
            </IconButton>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

LinkedInitiative.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  initiativeId: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default LinkedInitiative;
