import { defineMessages } from 'react-intl';

export const scope = 'app.sections.initiativeInfo';

export default defineMessages({
  Share: {
    id: `${scope}.Share`,
    defaultMessage: 'Share'
  },
  ShareOnLinkedIn: {
    id: `${scope}.ShareOnLinkedIn`,
    defaultMessage: 'Share on Linkedin'
  },
  ShareOnMail: {
    id: `${scope}.ShareOnMail`,
    defaultMessage: 'Share on Mail'
  },
  CopiedInitiativeURL: {
    id: `${scope}.CopiedInitiativeURL`,
    defaultMessage: 'Initiative URL copied!'
  },
  FailedCopiedInitiativeURL: {
    id: `${scope}.FailedCopiedInitiativeURL`,
    defaultMessage: 'Failed to copy initiative URL'
  },
  Privacy: {
    id: `${scope}.Privacy`,
    defaultMessage: 'Privacy'
  },
  Owner: {
    id: `${scope}.Owner`,
    defaultMessage: 'Owner'
  },
  Comments: {
    id: `${scope}.Comments`,
    defaultMessage: 'Comments'
  },
  InitiativeType: {
    id: `${scope}.InitiativeType`,
    defaultMessage: 'Initiative Type'
  },
  IndustrySector: {
    id: `${scope}.IndustrySector`,
    defaultMessage: 'Industry Sector'
  },
  RecommendedUserOrganizationLabel: {
    id: `${scope}.RecommendedUserOrganizationLabel`,
    defaultMessage: 'Recommended user or organization related to this project'
  },
  YouDontHaveAccessToThisInitiative: {
    id: `${scope}.YouDontHaveAccessToThisInitiative`,
    defaultMessage: 'You don’t have access to this initiative. Please raise a request to join !'
  },
  MessageLabelForOwner: {
    id: `${scope}.MessageLabelForOwner`,
    defaultMessage: 'Message'
  },
  WriteMessageForAdmin: {
    id: `${scope}.WriteMessageForAdmin`,
    defaultMessage: 'Write message for admin'
  },
  ContactInitiativeAdmin: {
    id: `${scope}.ContactInitiativeAdmin`,
    defaultMessage: 'Contact initiative admin'
  },
  AddToWatchList: {
    id: `${scope}.AddToWatchList`,
    defaultMessage: 'Add to watch list'
  },
  RemoveFromWatchList: {
    id: `${scope}.RemoveFromWatchList`,
    defaultMessage: 'Remove from watch list'
  },
  AddMoreKeywords: {
    id: `${scope}.AddMoreKeywords`,
    defaultMessage: "'Add more keywords'"
  },
  LeaveInitiative: {
    id: `${scope}.LeaveInitiative`,
    defaultMessage: 'Leave initiative'
  }
});
