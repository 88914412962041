import { Box, Dialog, Grid, IconButton, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIconComponent from 'components/@common/MuiMenu';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { IconLogout2, IconKey, IconUsersGroup, IconDotsVertical, IconX, IconSignRight } from '@tabler/icons-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconMenu2 } from '@tabler/icons-react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import messages from './messages';
import Search from './Search';
import HeaderIconComponent from './HeaderIconComponent';
import Logo from 'assets/images/Layer_1.svg';
import MobileLogo from 'assets/images/fav icon.svg';
import ChangePasswordDialog from 'layout/Header/HeaderContent/ChangePasswordDialog';
import paths from 'utils/constants/paths';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { imagePathResolver } from 'utils/imageResolvers';
import { useGetOrganizationInfoQuery } from 'store/rtk-query/organizationApi';
import { isRolePermitted } from 'utils/checkUserRoles';
import { ROLES } from 'utils/constants/roles';
import { Avatar, CustomChip } from 'components/@common';
import useLogout from 'hooks/useLogout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { dispatch } from 'store';
import { setTourState } from 'store/reducers/tourGuideReducer';
import { setInitiativesKeywordState, setNetworkingKeywordState } from 'store/reducers/searchKeywordsReducer';

// ==============================|| HEADER - CONTENT ||============================== //

const StyledImage = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: theme.spacing(4.4),
  [theme.breakpoints.down('md')]: {
    width: 120,
    height: 30,
    marginLeft: theme.spacing(2.5)
  },
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 30
  }
}));

const HeaderContent = ({ handleDrawerToggle }) => {
  const { pathname } = useLocation();
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const [UpComingOpen, setUpComingOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const { data: user } = useGetUserInfoQuery();
  const organizationUserId = user?.organization?.uuid;
  const { data: organizationInfo } = useGetOrganizationInfoQuery(organizationUserId, { skip: !organizationUserId });
  const intl = useIntl();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useLogout();
  const options = [
    isRolePermitted(user?.roles, ROLES.ADMIN) && {
      icon: <Avatar src={imagePathResolver(organizationInfo?.profileImage)} sx={{ height: '1.5rem', width: '1.5rem', mx: '0.5rem' }} />,
      id: 1,
      title: intl.formatMessage(messages.licenseAdministration),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        navigate(paths.LicenseAdministration.url);
      }
    },
    {
      icon: <IconKey style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} />,
      id: 2,
      title: intl.formatMessage(messages.changePassword),
      onClick: (event) => {
        event.stopPropagation();
        setOpenPasswordChange(true);
        handleClose();
      }
    },
    isRolePermitted(user?.roles, ROLES.SUPER_ADMIN) && {
      id: 3,
      icon: <IconUsersGroup style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} />,
      title: intl.formatMessage(messages.userManagement),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        navigate(paths.UserManagement.url);
      }
    },
    {
      icon: <IconSignRight style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} stroke={1.3} />,
      id: 5,
      title: intl.formatMessage(messages.appTourGuide),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        dispatch(
          setTourState({
            type: 'RESTART',
            stepKeys: pathname
          })
        );
      }
    },
    {
      icon: <IconLogout2 style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} />,
      id: 4,
      title: intl.formatMessage(messages.logout),
      onClick: handleLogout
    }
  ].filter(Boolean);

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleLogoClick = () => {
    navigate('/', { replace: true });
    setKeyword('');
    dispatch(
      setInitiativesKeywordState({
        relatedToMe: true,
        relatedToInitiativeId: '',
        filterKeywordState: [],
        offset: false
      })
    );
    dispatch(
      setNetworkingKeywordState({
        relatedToMe: true,
        relatedToInitiativeId: '',
        filterKeywordState: [],
        offset: false
      })
    );
  };

  return (
    <>
      <Box pt={0.25} className="hamburger-menu">
        <IconMenu2 cursor="pointer" size={35} stroke={1.5} color="black" onClick={handleDrawerToggle} />
      </Box>
      <StyledImage onClick={handleLogoClick} src={isMobile ? MobileLogo : Logo} width={160} height={40} alt="innofuse-logo" />
      <Search startIcon disable={false} keyword={keyword} setKeyword={setKeyword} />
      <CustomChip
        chipSx={{ p: { sm: '4px 6px', md: '4px 10px' }, bgcolor: '#00783E1A', color: 'success.main' }}
        label={
          <>
            Upcoming <br /> Features
          </>
        }
        onClick={() => setUpComingOpen(true)}
        variant={'filled'}
        className="header-news"
      />

      {isMobile && (
        <>
          <MenuIconComponent
            options={options}
            isMobile={isMobile}
            anchorEl={anchorEl}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            renderIcon={<IconDotsVertical />}
            popperWidth="24ch"
          />
          <ChangePasswordDialog setOpenPasswordChange={setOpenPasswordChange} openPasswordChange={openPasswordChange} />
        </>
      )}
      {!isMobile ? (
        <Grid sx={{ display: 'flex' }} gap={downLG ? 0.5 : 2}>
          <HeaderIconComponent />
        </Grid>
      ) : null}
      <Dialog open={UpComingOpen} onClose={() => setUpComingOpen(false)} fullWidth>
        <IconButton
          onClick={() => setUpComingOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 9999,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <IconX />
        </IconButton>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer defaultScale={0.57} fileUrl={process.env.REACT_APP_ROADMAP_PDF_LINK} />
        </Worker>
      </Dialog>
    </>
  );
};

export default HeaderContent;

HeaderContent.propTypes = {
  handleDrawerToggle: PropTypes.func
};
