import DOMPurify from 'dompurify';
import CryptoJS from 'crypto-js';
import { IconWorld, IconNotes, IconArchive } from '@tabler/icons-react';
import {
  IconBrandFeedly,
  IconFileCheck,
  IconThumbUp,
  IconNotebook,
  IconSchool,
  IconMessage2Share,
  IconUserSquareRounded
} from '@tabler/icons-react';
import {
  ACTIVITY_NAME,
  ROLE_LABEL,
  initiativeStateArr,
  initiativeStates,
  privacyItemsObj,
  visiblityItemsObj
} from './constants/initiatives';

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html)
  };
};

export const getButtonText = (actionType, initiativeId, initiativeState, intl, messages) => {
  const isDraft = actionType === 'draft';
  if (initiativeId) {
    switch (initiativeState) {
      case initiativeStates.PUBLISHED:
        return isDraft ? intl.formatMessage(messages.saveAsDraftButton) : intl.formatMessage(messages.updateInitiativeButton);
      case initiativeStates.ARCHIVED:
        return isDraft ? intl.formatMessage(messages.saveAsDraftButton) : intl.formatMessage(messages.publishButton);
      case initiativeStates.DRAFTED:
        return isDraft ? intl.formatMessage(messages.updateDraftButton) : intl.formatMessage(messages.publishButton);
      default:
        return intl.formatMessage(messages.updateInitiativeButton);
    }
  } else {
    return isDraft ? intl.formatMessage(messages.saveAsDraftButton) : intl.formatMessage(messages.publishButton);
  }
};

export const isStatePermitted = (stateObject) => {
  return initiativeStateArr.includes[stateObject];
};

/** function to check if user is an administrator of the initiative */
export const isAdministrator = (initiativeDetails, userId) => {
  return (
    initiativeDetails?.administrators?.some(({ uuid }) => uuid === userId) ||
    initiativeDetails?.administrators?.includes(userId) ||
    initiativeDetails?.isAdministrator === true
  );
};

/** function to check if user is an administrator of the initiative */
export const isOwner = (initiativeDetails, userId) => initiativeDetails?.userInfo?.uuid === userId;

/** function to check if user is a member of the initiative */
export const isMember = (initiativeDetails, userId) => {
  return (
    initiativeDetails?.members?.some(({ uuid }) => uuid === userId) ||
    initiativeDetails?.members?.includes(userId) ||
    initiativeDetails?.isMember === true
  );
};

/** function to check if user is a member of the initiative */
export const isInvitee = (initiativeDetails, userId) => {
  return (
    initiativeDetails?.invitees?.some(({ uuid }) => uuid === userId) ||
    initiativeDetails?.invitees?.includes(userId) ||
    initiativeDetails?.isInvitee === true
  );
};

/** function to check if user is a authorised entity of the initiative */
export const isAuthorizedEntity = (initiativeDetails, userId) => {
  return (
    initiativeDetails?.authorities?.some(({ uuid }) => uuid === userId) ||
    initiativeDetails?.authorities?.includes(userId) ||
    initiativeDetails?.isAuthority === true
  );
};

export const isUserEligible = (initiativeDetails) => {
  return (
    initiativeDetails?.visibility === visiblityItemsObj.PUBLIC ||
    initiativeDetails?.visibility === visiblityItemsObj.PLATFORM_MEMBERS ||
    (initiativeDetails?.visibility === visiblityItemsObj.PRIVATE && (initiativeDetails?.isMember || initiativeDetails?.isAdministrator))
  );
};

export const isVisible = (initiativeDetails, membersList, userId) => {
  return (
    initiativeDetails?.visibility === visiblityItemsObj.PUBLIC ||
    initiativeDetails?.visibility === visiblityItemsObj.PLATFORM_MEMBERS ||
    (initiativeDetails?.visibility === visiblityItemsObj.PRIVATE &&
      (membersList?.some(({ uuid }) => uuid === userId) || isAdministrator(initiativeDetails, userId)))
  );
};

/** Return Icon based on initiatives states */
export const returnIconBasedOnInitiativesStates = (initiativeState) => {
  switch (initiativeState) {
    case initiativeStates.PUBLISHED:
      return <IconWorld stroke={1.5} />;
    case initiativeStates.ARCHIVED:
      return <IconArchive stroke={1.5} />;
    case initiativeStates.DRAFTED:
      return <IconNotes stroke={1.5} />;
    default:
      return null;
  }
};

/** Show join button if user is not admin, not member but authorized and inititiative state is only published */
export const isJoinInitiativesPermitted = (initiativeDetails, userId) => {
  if (!initiativeDetails || !userId) return false;
  const isUserInAdministrators = isAdministrator(initiativeDetails, userId);
  const isUserInMembers = isMember(initiativeDetails, userId);
  const isUserInAuthorizedEntities = isAuthorizedEntity(initiativeDetails, userId);
  // Determine if the "Join" button should be visible
  return (
    !isUserInMembers &&
    !isUserInAdministrators &&
    initiativeDetails.initiativeState === initiativeStates.PUBLISHED &&
    (initiativeDetails?.privacy === privacyItemsObj.PUBLIC ||
      (initiativeDetails?.privacy === privacyItemsObj.RESTRICTED && isUserInAuthorizedEntities))
  );
};

/** Filter users if they present in invitee or administrators array */
export const filteredOption = (options, userId, autocompleteStates = [], inviteesList = []) => {
  const filteredSelfUserOptions = options?.filter((item) => item.uuid !== userId);
  if (filteredSelfUserOptions && inviteesList.length > 0) {
    return filteredSelfUserOptions.filter((item) => !inviteesList?.some((x) => x.uuid === item.uuid));
  } else if (filteredSelfUserOptions && autocompleteStates.length > 0) {
    return filteredSelfUserOptions.filter((item) => !autocompleteStates?.some((x) => x.uuid === item.uuid));
  } else {
    return filteredSelfUserOptions || [];
  }
};

export const getRoleLabelForInitiativeBox = (initiativeDetails, userId) => {
  if (!initiativeDetails || !userId) return '';
  const isUserInAdministrators = isAdministrator(initiativeDetails, userId);
  const isUserInMembers = isMember(initiativeDetails, userId);
  if (isUserInAdministrators) {
    return ROLE_LABEL.ADMINISTRATOR;
  } else if (isUserInMembers) {
    return ROLE_LABEL.MEMBER;
  }
};

export const filterInviteesOptions = (options, inviteeList, administratorList, userId) => {
  const result = options.filter(
    (item) =>
      item.uuid !== userId && !inviteeList.some((val) => val.uuid === item.uuid) && !administratorList?.some((x) => x.uuid === item.uuid)
  );
  return result;
};

export const createUserName = (userInfo) =>
  userInfo
    ? `${userInfo?.academicTitles ? userInfo?.academicTitles : ''} ${
        userInfo?.firstName ? userInfo?.firstName : userInfo?.name ? userInfo?.name : 'Anonymous'
      } ${userInfo?.lastName ? userInfo?.lastName : ''}`
    : '';

export const getActivityIconBasedOnTableName = (tableName) => {
  const iconMap = {
    [ACTIVITY_NAME.CONTRIBUTIONS]: <IconBrandFeedly size={20} stroke={1.8} />,
    [ACTIVITY_NAME.INITIATIVE]: <IconNotebook size={20} stroke={1.8} />,
    [ACTIVITY_NAME.LITERATURE]: <IconFileCheck size={20} stroke={1.8} />,
    [ACTIVITY_NAME.REACTION]: <IconThumbUp size={20} stroke={1.8} />,
    [ACTIVITY_NAME.TRAINING]: <IconSchool size={20} stroke={1.8} />,
    [ACTIVITY_NAME.COMMENT]: <IconMessage2Share size={20} stroke={1.8} />,
    [ACTIVITY_NAME.Initiative_INVITEE]: <IconUserSquareRounded size={20} stroke={1.8} />
  };
  return iconMap[tableName] || null;
};

export const generateSHA512 = (str, iterations = 1000) => {
  if (!str) throw new Error('Something Went Wrong');
  if (iterations === 0) return str;
  return generateSHA512(CryptoJS.SHA512(str).toString().toLowerCase(), iterations - 1);
};
