import { Fab } from '@mui/material';
import { IconPencil } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const FabIcon = ({ iconToRender, onClick, sx, disabled, className = 'profile-details' }) => {
  return (
    <Fab
      onClick={onClick}
      disabled={disabled}
      sx={{
        '::after': {
          transition: 'all 0.5s'
        },
        ':active::after': {
          transition: '0s'
        },
        position: 'absolute',
        top: 0,
        right: 0,
        bgcolor: 'white',
        background: 'transparent',
        boxShadow: 'none',
        color: 'primary.main',
        ...sx
      }}
      className={className}
    >
      {iconToRender || <IconPencil size={'1.8rem'} />}
    </Fab>
  );
};

export default FabIcon;

FabIcon.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  iconToRender: PropTypes.element,
  className: PropTypes.string
};
